<template>
  <div class="Footer">
    <div @click="gotoHomePage"><img class="icon-icon-block" src="https://cdn.ethalt.com/logo.png" /> Reward With Shopaghetti</div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    gotoHomePage() {
      window.open('https://shopaghetti.com/')
    }
  }
};
</script>

<style scoped>
.Footer {
  position: fixed;
  height: 52px;
  font-size: 14px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 -3px 12px 0 rgba(0, 0, 0, 0.04);
  z-index: 1;
}

.Footer > div {
  display: flex;
}

.icon-icon-block {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
</style>
