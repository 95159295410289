const reponseState = {
    SUCCESS_CODE: 1,
    
    FAIL_CODE: 0,

    // 开启登陆
    // userObj: { rewardCount: 0, points: 0, id: '', name: '' },
    // urlObj: {
    //     "callback": "{\"customerId\":\"4101117182107\",\"myshopifyDomain\":\"try-app-store.myshopify.com\"}",
    //     "host": "try-app-store.myshopify.com"
    // },
}

export default reponseState