import * as types from './mutations-types'
const matutions = {
    // 携带参数
    [types.SETURLOBJ](state, url) {
        state.urlObj = url;
    },
    // 是否再首页
    [types.SETINHOMEPAGE](state, falg) {
        state.inHomePage = falg;
    },
    // 用户积分
    [types.SETUSERPOINTS](state, points) {
        state.userObj.points = points;
    },
    // 卡券数量
    [types.SETUSERREWARDSCOUNT](state, count) {
        state.userObj.rewardCount = count;
    },
    // 分享积分规则
    [types.SETSHAREOBJ](state, obj) {
        state.shareObj = obj;
    },
    // 设置主题色
    [types.SETTHEMECOLOR](state, color) {
        state.themeColor = color;
    },
    // 设置字体颜色
    [types.SETFONTCOLOR](state, color) {
        state.fontColor = color;
    },
    // 设置标题
    [types.SETPROGRAMNAME](state, name) {
        state.programName = name;
    },
    // 设置货币单位
    [types.SETCURRENCY](state, currency) {
        state.currency = currency;
    },
    //设置店铺名
    [types.SETSHOPNAME](state, name) {
        state.shopName = name;
    },
    //设置用户FirstName
    [types.SETCUSTOMERFIRSTNAME](state, name) {
        state.customerFirstName = name;
    },
    //设置用户LastName
    [types.SETCUSTOMERLASTNAME](state, name) {
        state.customerLastName = name;
    },
    // 设置加载效果
    [types.SETLOADING](state, loading) {
        state.loading = loading;
    },
    // 加载优惠券
    [types.SETREDEEMLIST](state, redeemList) {
        state.redeemList = redeemList;
    },
    // 加载奖励规则
    [types.SETEARNWAYS](state, earnWays) {
        state.earnWays = earnWays;
    },
    // 加载当前用户的所有优惠卷
    [types.SETREWARDLIST](state,rewardList){
        state.rewardList=rewardList;
    },
    //加载优惠卷详情
    [types.SETREWARDDETAIL](state,rewardDetail){
        state.rewardDetail=rewardDetail;
    },
    // 初始化配置
    [types.SETWIDGETSETTINGINITIAL](state, initial) {
        state.widgetSettingInitial = initial;
    },
    // 设置剩余
    [types.SETUNSET](state, unset) {
        state.friendPrizeValue = unset.friendPrizeValue;
        state.friendPrizeType = unset.friendPrizeType;
        state.customerPrizeValue = unset.customerPrizeValue;
        state.customerPrizeType = unset.customerPrizeType;
        state.customerPrizeSwitch = unset.customerPrizeSwitch;
        state.pointSwitch = unset.pointSwitch;
        state.referralSwitch = unset.referralSwitch;
        state.friendPrizeSwitch = unset.friendPrizeSwitch;
        state.pointAlias = unset.pointAlias.toLowerCase();
    },
}

export default matutions
