

import * as actions from './actions'
import * as getters from './getters'
import state from './state'
import mutations from './mutations'
import reponseState from './responseState'
// import createLogger from 'vuex/dist/logger'
import { createStore } from 'vuex'

// debug 
// const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
    actions,
    getters,
    state,
    mutations,
    reponseState,
    
    // strict: debug,
    // plugins: debug ? [createLogger()] : []
})
export default store
