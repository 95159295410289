export function sendMsg(window, msg) {
  window.parent.postMessage({ msg, }, "*");
}


export function firstUpperCase([first, ...reset]) {
  return first.toUpperCase()+reset.join('');
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}