import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import { store } from '../store';


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  // {
    // path: '/about',
    // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/earndir',
    name: 'Earndir',
    component: () => import('../views/Earndir.vue')
  },
  {
    path: '/reddeem',
    name: 'Reddeem',
    component: () => import('../views/Reddeem.vue')
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: () => import('../views/Rewards.vue')
  },
  {
    path: '/rewardDetail',
    name: 'RewardDetail',
    component: ()=>import('../views/RewardDetail.vue')
  }
]
const basepath = '/';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: basepath,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.name === 'Home') {
    store.commit('SET_INHOME_PAGE', true)
  } else {
    store.commit('SET_INHOME_PAGE', false)
  }
  store.commit('SET_LOADING', true);
  next();
});

export default router
