const state = {
    shareObj: {},
    inHomePage: true,
    userObj: {
      points: 0,
      rewardCount: 0,
    },
    urlObj: {},
    currency: {
      isoCode: "USD"
    },
    redeemList: [],
    themeColor: '#510606',
    fontColor: '#ffffff',
    programName: 'my store',
    friendPrizeValue: '--',
    friendPrizeType: 0,
    shopName: '',
    customerFirstName: '',
    customerLastName: '',
    earnWays: [],
    rewardList: [],
    rewardDetail: [],
    loading: true,
    customerPrizeSwitch:1,
    pointSwitch:1,
    referralSwitch:1,
    friendPrizeSwitch:1,
    widgetSettingInitial: false,
    pointAlias: "points",
}

export default state
