import state from "./state";

export const urlObj = state => state.urlObj;

export const hostUrl = state => state.hostUrl;

export const inHomePage = state => state.inHomePage;

export const userObj = state => state.userObj;

export const hadLogin = state => state.urlObj.customerid ? true : false;

export const shareObj = state => state.shareObj;

export const themeColor = state => state.themeColor;

export const fontColor = state => state.fontColor;

export const programName = state => state.programName;

export const shopName = state => state.shopName;

export const friendPrizeValue = state => state.friendPrizeValue;

export const friendPrizeType = state => state.friendPrizeType;

export const customerPrizeType = state => state.customerPrizeType;

export const customerPrizeSwitch = state => state.customerPrizeSwitch;

export const pointSwitch = state => state.pointSwitch;

export const referralSwitch = state => state.referralSwitch;

export const friendPrizeSwitch = state => state.friendPrizeSwitch;

export const customerPrizeValue = state => state.customerPrizeValue;

export const currency = state => state.currency;

export const pointAlias = state => state.pointAlias;

export const customerFirstName = state => state.customerFirstName;

export const customerLastName = state => state.customerLastName;

export const loading = state => state.loading;

export const redeemList = state => state.redeemList;

export const earnWays = state => state.earnWays;

export const widgetSettingInitial = state => state.widgetSettingInitial;

export const rewardList = state => state.rewardList;

export const rewardDetail = state => state.rewardDetail;





