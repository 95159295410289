<template>
  <div class="badge-container">
    <i v-if="icon" :class="['iconfont', iconType(icon)] " class="icon"></i>
    <slot class="badge-text"></slot>
  </div>
</template>

<script>
export default {
  name:'item-badge',
  props: {
    icon: {
      type: String,
      default: null,
    }
  },
  methods: {
    iconType(type) {
      return {
        right: 'right-arrow',
        arrow: 'iconright-arrow',
      }[type];
    },
  }
}
</script>

<style scoped>
  .badge-container {
    color: #fff;
    border-radius: 15px;
    background-color: #8B35FF;
    text-align: center;
    align-items: center;
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    width: 58px;
  }
  .badge-container i {
    width: 14px;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
  }
  .badge-container i::before {
    display: inline-block;
    vertical-align: middle;
  }
  .clicked {
    display: block;
  }
</style>
