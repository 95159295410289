import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

import api from '@/request/api'

const app = createApp(App)
app.config.globalProperties.$api = api
app.config.errorHandler = ((error, vm) => {
    console.error(error);
})
app.use(router).use(store).mount('#app')



