<template>
  <router-view v-slot="{ Component }">
    <Spin v-bind:loading="loading">
      <Background v-bind:backgroundImage="convertBackgroundColor(themeColor)"/>
      <Header/>
      <component :is="Component" class="container" style="padding-bottom: 52px;"></component>
      <Footer />
    </Spin>
  </router-view>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Spin from "./components/Spin";
import Background from "./components/Background";
import { hexToRgb } from "./util";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      hi: "",
      SignInUrl: "",
    };
  },
  components: {
    Header,
    Footer,
    Spin,
    Background,
  },
  computed: {
    ...mapGetters({
      shareObj: "shareObj",
      urlObj: "urlObj",
      userObj: "userObj",
      loading: "loading",
      themeColor: "themeColor",
    }),
  },
  created() {},
  // 初始化
  mounted() {
    // Entry
    let obj = this.formatUrl(window.location.search);
    this.SETURLOBJ(obj);
    this.SETINHOMEPAGE(false);
    // 获取设置信息
    Promise.all([this.$api.widget(), this.$api.reffer()]).then(([widget, reffer]) => {
      this.widgetSetting(widget);
      this.refferInfo(reffer);
      this.spinLoading(false);
    });
  },
  methods: {
    ...mapMutations({
      SETURLOBJ: "SET_URL_OBJ",
      SETSHAREOBJ: "SET_SHARE_OBJ",
      SETINHOMEPAGE: "SET_INHOME_PAGE",
    }),
    ...mapActions(["widgetSetting", "spinLoading", "refferInfo"]),
    formatUrl(url) {
      url = decodeURI(url);
      let search = url.substr(1);
      if (typeof search !== "string" || !search) return search;
      return search.split("&").reduce((res, cur) => {
        const arr = cur.split("=");
        return Object.assign({ [arr[0]]: arr[1] }, res);
      }, {});
    },
    convertBackgroundColor(color) {
      const rgb = hexToRgb(color);
      return `linear-gradient(rgb(${rgb.r} ${rgb.g} ${rgb.b}), rgb(${rgb.r} ${rgb.g} ${rgb.b}) 12%, #FFFFFF)`;
    },
    close() {},
  },
};
</script>
<style>
#app {
  font-family: 'Roboto', sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
