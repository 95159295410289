import * as types from './mutations-types';

const iconMap = ['', 'iconcouponfill', 'iconcouponf'];

export const widgetSetting = function ({ commit, state }, { data:res }) {
  const { data } = res;
  const { shopName, widgetSetting: { programName, themeColor, fontColor, ...setting }, currency, ...unset } = { ...state, ...data };

  const { urlObj } = state;
  // 判断是否登陆
  if (!!urlObj.customerid) {
    commit(types.SETUSERPOINTS, data.points);
    commit(types.SETUSERREWARDSCOUNT, data.rewardCount);
    commit(types.SETCUSTOMERFIRSTNAME, data.customerFirstName);
    commit(types.SETCUSTOMERLASTNAME, data.customerLastName);
  }
  commit(types.SETSHOPNAME, shopName);
  commit(types.SETTHEMECOLOR, themeColor);
  commit(types.SETFONTCOLOR, fontColor);
  commit(types.SETCURRENCY, currency);
  commit(types.SETPROGRAMNAME, programName);
  commit(types.SETWIDGETSETTINGINITIAL, true);
  commit(types.SETUNSET, { ...unset, ...setting });
}

export const redeemInfo = function({ commit, state }, { data:res }) {
  const { data } = res;
  const { currency, pointAlias } = state;
  commit(types.SETREDEEMLIST, data.map((item, index) => {
    const info = {};
    const { redeemList } = state;
    //   cost
    info.redeemRuleId = item.redeemRuleId;
    info.points = item.points;
    info.icon = iconMap[item.prizeType];
    if(item.prizeType==1){
      info.tit = `${currency.symbol}${item.prizeValue} off coupon`;
    }else if(item.prizeType==2){
      info.tit = `${item.prizeValue}% off coupon`;
    }
    info.subtitle = `${item.points} ${pointAlias}`;
    return { ...redeemList[index], ...info }
  }));
}

export const queryEarnWays = function({ commit, state }, { data:res }) {
  const { data } = res;
  const { currency, pointAlias } = state;
  const earnWayInfo = [{
    index: 'placeOrderValue',
    switch: 'placeOrderSwitch',
    icon: "iconshoppingBag",
    tit: "Place an order",
    subtitle: pointAlias,
  },
  {
    index: 'birthdayValue',
    switch: 'birthdaySwitch',
    icon: "iconbirthdayCake",
    tit: "Celebrate a birthday",
    subtitle: pointAlias,
  },
  {
    index: 'registerValue',
    switch: 'registerSwitch',
    icon: "iconstore",
    tit: "Signup",
    subtitle: pointAlias,
  }];
  function earnWayTitle(item, data) {
    if (item.index == 'placeOrderValue' && data['placeOrderType'] == 'points_per_spent') {
      return `${data[item.index]} ${pointAlias} for every ${currency.symbol}1 spent`;
    }
    return data[item.index]+" "+item.subtitle;
  }
  commit(types.SETEARNWAYS, earnWayInfo.map((item) => {
    return {
      ...item,
      subtitle: earnWayTitle(item, data),
      switch: data[item.switch],
      value: data[item.index]
    };
  }).filter((item) => item.switch == 1));
}

export const refferInfo = function({ commit, state }, { data:res }) {
  const { data } = res;
  commit(types.SETSHAREOBJ, { ...state.shareObj, ...data });
}

export const rewardList = function({ commit, state }, { data:res }) {
  const { data } = res;
  const { currency, pointAlias } = state;
  commit(types.SETREWARDLIST, data.map((item) =>{
      const reward = {};
      reward.routePath = "RewardDetail";
      reward.icon = `${iconMap[item.prizeType]} iconfont`;
      if(item.prizeType==1){
        // reward.tit = `${currency.symbol}${item.prizeValue} off coupon`;
        // 修改：将 ￥70 off coupon 拆分为两个部分，一部分是 ￥70， 一部分是 off coupon
        reward.tit = 'off coupon'
        reward.currency = `${currency.symbol}${item.prizeValue}`
      } else if (item.prizeType == 2) {
        reward.tit = 'off coupon';
        reward.currency = `${item.prizeValue}%`
      }
      if(item.costType==5) {
        reward.subtitle = `Spent ${item.costValue} ${pointAlias}`;
      } else if (item.costType==4) {
        reward.subtitle = 'Rewarded By Referral'
      }
      reward.discountCode = item.discountCode;
      return {...reward};
  }));
}

export const rewardDetail = function({ commit }, data) {
    commit(types.SETREWARDDETAIL, [data]);
}

export const spinLoading = function({ commit }, loading) {
  commit(types.SETLOADING, loading);
}