<template>
  <div class="home"> 
    <!-- rewards -->
    <CardItemContainer
      v-if="hadLogin" 
      :items="[{ tit: 'Your rewards', subtitle : `You have ${userObj.rewardCount} reward available`, routePath: 'Rewards' }]" 
    >
      <template v-slot:default="slotProps">
        <Badge 
          icon="arrow" :style="{width: '57px', height: '30px', backgroundColor: themeColor, color:fontColor}" 
          @click="changeRouter(slotProps.item.routePath)"/>
      </template>
    </CardItemContainer> 

    <!-- register -->
    <CardItemContainer 
      class="register"
      style="margin-top: 20px;"
      v-if="!hadLogin" 
      title="Become a member"
      :items="[{ subtitle: 'With more ways to unlock exciting perks, this is your all access pass to exclusive rewards.' }]" 
    >
      <div class="join-button" @click="send('Register')" :style="{backgroundColor: themeColor,color: fontColor}">Join Now</div>
      <div class="sign-button">
        Already have an account?
        <a class="a-link" @click="send('Sign')">Sign in<i class="iconfont iconright-arrow-2"></i></a>
      </div>
    </CardItemContainer>

    <!-- Poins -->
    <CardItemContainer
      :title="`${userObj.points} ${firstUpperCase(pointAlias)}`"
      :items="pointsList"
    >
      <i class="iconfont iconright-arrow-2" style="font-size: 8px"></i>
    </CardItemContainer>

    <!-- Referrals -->
    <CardItemContainer
      v-if="!hadLogin && referralSwitch == 1 && (friendPrizeSwitch == 1 || customerPrizeSwitch == 1)"
      class="referral"
      title="Referrals"
      :items="[{ subtitle: `Give your friends rewards, and collecting your own rewards when they make a purchase!` }]"
    >
      <div
        class="referral-item"
        v-for="(reffer, index) in setRefferList({ currency, friendPrizeValue, friendPrizeType, customerPrizeValue, customerPrizeType, customerPrizeSwitch, friendPrizeSwitch })"
        v-bind:key="index"
      >
        <p class="referral-content">{{ reffer.tit }}<span class="referral-text" :style="{color:themeColor}">{{ reffer.emphasis }}</span>{{ reffer.subtitle }}</p>
      </div>
    </CardItemContainer>

    <!-- Share -->
    <CardItemContainer
      v-if="hadLogin && referralSwitch == 1 && (friendPrizeSwitch == 1 || customerPrizeSwitch == 1)"
      class="share"
      title="Refer Your Friends"
      :items="[{ subtitle: `Share this URL to give your friends the reward ${friendPrizeType == 2 ? friendPrizeValue+'%' : currency.symbol+friendPrizeValue} off coupon.` }]"
    >
      <com-input class="copy-input" :value="shareObj.shareUrl" />
      <div class="brand">
        <div class="sharing-option" @click="shareToFaceBook">
          <img src="../../public/images/fb.png" alt="">
        </div>
        <div class="sharing-option" @click="shareToTwitter">
          <img src="../../public/images/twitter.png" alt="">
        </div>
        <div class="sharing-option" @click="shareToEmail">
          <img src="../../public/images/email.png" alt="">
        </div>
      </div>
    </CardItemContainer>
  </div>
</template>

<script>
// @ is an alias to /src
import Badge from "@/components/Badge";
import ComInput from "@/components/ComInput";
import CardItemContainer from "@/components/CardItemContainer";
import { sendMsg, firstUpperCase } from "../util";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Home",
  data() {
    const pointsListItemOnclick = function(item) { item.routePath && this.$router.push({ path: item.routePath }) };
    return {
      pointsList: [{
        icon: "iconmoney",
        subtitle: "Ways to earn",
        routePath: "earndir",
        onClick: pointsListItemOnclick,
      }, {
        icon: "icongift",
        subtitle: "Ways to redeem",
        routePath: "reddeem",
        onClick: pointsListItemOnclick,
      }],
    };
  },
  mounted() {
    this.widgetSettingInitial && this.spinLoading(false);
  },
  components: {
    CardItemContainer,
    Badge,
    ComInput,
  },
  computed: {
    ...mapGetters({
      hostUrl: "hostUrl",
      inHomePage: "inHomePage",
      hadLogin: "hadLogin",
      urlObj: "urlObj",
      userObj: "userObj",
      shareObj: "shareObj",
      currency: "currency",
      themeColor: "themeColor",
      fontColor: "fontColor",
      widgetSettingInitial: "widgetSettingInitial",
      friendPrizeValue: "friendPrizeValue",
      friendPrizeType: "friendPrizeType",
      customerPrizeType: "customerPrizeType",
      customerPrizeValue: "customerPrizeValue",
      customerPrizeSwitch: "customerPrizeSwitch",
      friendPrizeSwitch: "friendPrizeSwitch",
      pointSwitch: "pointSwitch",
      referralSwitch: "referralSwitch",
      shopName: "shopName",
      customerFirstName: "customerFirstName",
      customerLastName: "customerLastName",
      pointAlias: "pointAlias",
    }),
  },
  methods: {
    firstUpperCase,
    ...mapActions(["spinLoading"]),
    setRefferList({ currency, friendPrizeValue, friendPrizeType, customerPrizeValue, customerPrizeType, ...unset }) {
      return [{
        tit: "They get ",
        subtitle: friendPrizeType == 3 ? '' : ' off coupon',
        emphasis: friendPrizeType == 2 ? friendPrizeValue+'%' : (friendPrizeType == 3 ? friendPrizeValue+' '+unset.pointAlias : currency.symbol+friendPrizeValue),
        switch: unset.friendPrizeSwitch,
      }, {
        tit: "You get ",
        subtitle: customerPrizeType == 3 ? '' : ' off coupon',
        emphasis: customerPrizeType == 2 ? customerPrizeValue+'%' : (customerPrizeType == 3 ? customerPrizeValue+' '+unset.pointAlias : currency.symbol+friendPrizeValue),
        switch: unset.customerPrizeSwitch,
      }].filter(item => item.switch == 1);
    },
    send(msg) {
      sendMsg(window, msg);
    },
    shareToFaceBook: function(){
      window.open("https://www.facebook.com/sharer/sharer.php?u=".concat(this.shareObj.shareUrl));
    },
    shareToTwitter: function(){
      window.open("https://twitter.com/intent/tweet?text= Visit "+this.shopName+" and receive your free gift!&url=".concat(this.shareObj.shareUrl))
    },
    shareToEmail: function(){
      var textOfDiscountCodeType = '';
      if(this.friendPrizeType == 1){
        textOfDiscountCodeType = `￥${this.friendPrizeValue} off coupon`;
      }else if(this.friendPrizeType == 2){
        textOfDiscountCodeType = `${this.friendPrizeValue}% off coupon`;
      }
      window.location.href="mailto:?subject="+this.customerLastName+" "+this.customerFirstName+" sent you a gift!&body=Get a "+textOfDiscountCodeType+" to use on your first purchase at "+this.shopName+". Just use the following link: ".concat(this.shareObj.shareUrl);
    },
    changeRouter: function(path){
      path && this.$router.push({ path });
    }
  },
};
</script>

<style scoped>
.a-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #530606;
}
.referral ::v-deep(.container-list), .register ::v-deep(.container-list), .share ::v-deep(.container-list) {
  padding-top: 0;
  padding-bottom: 8px;
  flex-direction: column;
}
.referral ::v-deep(.container-right), .register ::v-deep(.container-right), .share ::v-deep(.container-right) {
  flex: 1;
  width: 100%;
  margin-top: 20px;
}
.referral ::v-deep(.sub-tit), .register ::v-deep(.sub-tit), .share ::v-deep(.sub-tit) {
  width: 100%;
  overflow: inherit;
  white-space: normal;
}
.register ::v-deep(.join-button) {
  width: 100%;
  padding: 8px 0;
  font-weight: bold;
  text-align: center;
  border-radius: 18px;
  color: #fff;
  background-color: #9141FF;
  overflow: inherit;
  white-space: normal;
}
.register ::v-deep(.sign-button) {
  font-weight: 600;
  margin-top: 24px;
}
.register ::v-deep(.a-link) {
  display: inline-flex;
  float: right;
  color: #898989;
}
.register ::v-deep(.a-link i) {
  font-size: 8px;
  padding-left: 6px;
  font-weight: bold;
}
.referral ::v-deep(.referral-content) {
  text-align: center;
  margin: 6px 0;
  padding: 6px 0;
  font-size: 14px;
  background-color: #F7F7F7;
}
.referral ::v-deep(.referral-content:first-child) {
  margin-top: 0px;
}
.referral ::v-deep(.referral-text) {
  color: #9141FF;
  font-weight: 600;
}
.share ::v-deep(.container-right) {
  margin-top: 12px;
}
.share ::v-deep(.copy-input) {
  margin-bottom: 24px;
}
.share ::v-deep(.brand) {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 24px;
}
.share ::v-deep(.sharing-option img) {
  width: 40px;
  height: 40px;
}
</style>
