<!-- input -->
<template>
  <div class="copy-text-container">
    <input readonly ref="myRef" class="text-input" type="text" :value="value" />
    <div @click="copyText" class="copy-container">
      <img src="../../public/images/copy.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    copyText() {
      let input = this.$refs.myRef;
      input.select();
      document.execCommand("copy");
    }
  }
}
</script>

<style socped>
.copy-text-container {
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
}
.text-input {
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Proxima Nova, arial, sans-serif;
  border: none;
  margin: 0;
  padding: 1rem 2.5rem 1rem 1rem;
  font-size: 14px;
  color: #A2A2A2FF;
  outline: none;
  background-color: #F5F5F5;
  -webkit-appearance: none;
  width: 100%;
  -moz-appearance: none;
  user-select: all;
  appearance: none;
  position: relative;
}
.copy-container {
  width: 19px;
  height: 19px;
  position: absolute;
  right: 14px;
  background-color: transparent;
}
.copy-container img {
  margin-right: 24px;
  width: 100%;
}
.system-btn {
  position: absolute;
  right: 24px;
  background-color: transparent;
  border: none;
  text-align: left;
  font-weight: inherit;
  line-height: inherit;
  font: inherit;
  height: 1rem;
  width: 1rem;
  padding: 8px;
  cursor: pointer;
}
</style>
