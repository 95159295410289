<template>
  <div class="background" v-bind:style="{ backgroundImage: backgroundImage }"></div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    backgroundImage: {
      type: String,
      default: "inherit",
    },
  },
};
</script>

<style scoped>
.background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
}
</style>
