<template>
  <transition name="spin-fade">
    <div v-if="loading" class="loader-container">
      <div class="loader-block">
        <div></div>
        <div></div>
      </div>
    </div>
  </transition>
  <div :class="{ 'spin-content-container':true, loaded: !loading }"><slot></slot></div>
</template>

<script>
export default {
  name: "Spin",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
};
</script>

<style scoped>
.spin-fade-enter-active, .spin-fade-leave-active {
  transition: opacity .5s;
}
.spin-fade-enter, .spin-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(.6);
    transform: rotate(180deg) scale(.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
}
.loader-block{
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
}
.loader-block>div{
  position: absolute;
  left: -20px;
  top: -20px;
  border: 2px solid #b7b7b7;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  -webkit-animation: rotate 1s 0s ease-in-out infinite;
  animation: rotate 1s 0s ease-in-out infinite;
}
.loader-block>div:last-child{
  display: inline-block;
  top: -10px;
  left: -10px;
  width: 15px;
  height: 15px;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  border-color: #b7b7b7 transparent;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.spin-content-container {
  opacity: 0;
}
.spin-content-container.loaded {
  transition: opacity .2s;
  opacity: 1;
}
</style>
