export const SETURLOBJ = 'SET_URL_OBJ';

export const SETHOST = 'SET_HOST';

export const SETINHOMEPAGE = 'SET_INHOME_PAGE';

export const SETUSERPOINTS = 'SET_USERPOINTS';

export const SETUSERREWARDSCOUNT = 'SET_USEWARDSCOUNT';

export const SETSHAREOBJ = 'SET_SHARE_OBJ';

export const SETUSERID = 'SET_USERID';

export const SETTHEMECOLOR = 'SET_THEME_COLOR';

export const SETFONTCOLOR = 'SET_FONT_COLOR';

export const SETPROGRAMNAME = 'SET_PROGRAM_NAME';

export const SETUNSET = 'SET_UNSET';

export const SETCURRENCY = 'SET_CURRENCY';

export const SETSHOPNAME = 'SET_SHOP_NAME';

export const SETCUSTOMERFIRSTNAME = 'SET_CUSTOMER_FIRST_NAME';

export const SETCUSTOMERLASTNAME = 'SET_CUSTOMER_LAST_NAME';

export const SETLOADING = 'SET_LOADING';

export const SETREDEEMLIST = 'SET_REDEEM_LIST';

export const SETREWARDLIST = 'SET_REWARD_LIST';

export const SETREWARDDETAIL='SET_REWARD_DETAIL';

export const SETEARNWAYS = 'SET_EARN_WAYS';

export const SETWIDGETSETTINGINITIAL = 'SET_WIDGET_SETTING_INITIAL';


