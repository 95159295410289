<template>
  <div class="card-item-container">
    <!-- 块大标题 -->
    <div v-if="title" class="container-title">{{title}}</div>
    <!-- list -->
    <div 
      v-for="(item, index) in items" 
      :key="index" 
      class="container-list" 
      @click="onClick(item)" 
      :style="item.styles || {}" 
      >
      <!-- left = icon + title + subTitle -->
      <!-- 左边图标 -->
      <slot name="left" :item="item">
        <div class="container-left" v-if="item.icon">
          <div class="icon">
            <img :src="imgUrl(item.icon)">
          </div>
        </div>
      </slot>
      <!-- 中间标题 -->
      <div class="container-center">
        <div class="tit">{{item.tit}}</div>
        <div class="sub-tit">{{item.subtitle}}</div>
      </div>
      <!-- right = 组件 -->
      <!-- 创建一个组件，v-if决定组件显示与否 -->
      <div class="container-right">
        <!-- 放进来一个组件 -->
        <div class="container-right-content">
          <!-- 插槽放入组件 -->
          <slot :item="item"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hexToRgb } from "../util/index.js";
export default {
  name:'Card-item-container',
  // title,subtitle,组件中的数据 都需要props进来
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => { return [] },
    },
  },
  methods: {
    imgUrl(iconType) {
      return {
        iconmoney: require('../../public/images/earnWays.png'),
        icongift: require('../../public/images/reddemWays.png'),
        iconshoppingBag: require('../../public/images/order.png'),
        iconbirthdayCake: require('../../public/images/celebrate.png'),
        iconstore: require('../../public/images/signup.png'),
        iconcouponfill: require('../../public/images/couponfill.png'),
        iconcouponf: require('../../public/images/iconcouponpercent.png')
      }[iconType];
    },
    onClick(item) {
      if(typeof item.onClick == 'function') {
        item.onClick.apply(this, [item]);
      }
      return false;
    },
    // 传进去的其实要是主题色
    // 因为上一层传过来的是 color:themeColor
    convertBackgroundColor(color) {
      const rgb = hexToRgb(color);
      console.log(rgb);
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b},0.1)`;
    },
  },
  onMounted() {
    // this.convertBackgroundColor("#FF0000")
    console.log(this.items[0].styles.color)
  }
}
</script>

<style scoped>
  .card-item-container {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    margin: 15px 30px;
    align-items: center;
    padding: 6px 16px;
  }
  .container-title {
    font-size: 18px;
    color: #0E0300FF;
    font-weight: 600;
    padding: 9px 0;
  }
  .container-list {
    overflow: hidden;
    /* color: #9448FF;; */
    display: flex;
    align-items: center; 
    border-bottom: 1px solid #DADADAFF;
    padding: 15px 0;
  }
  .container-list:last-child{
    border: none;
  }
  .container-left {
    display: flex;
    text-align: center;
    margin-right: 10px;
  }
  .container-left .icon {
    width: 20px;
    height: 100%;
  } 
  .container-left .icon img {
    width: 100%;
    display: block;
  }
  .container-center {
    flex: 1;
  }
  .container-center .tit {
    color: inherit;
    width: 200px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .container-center .sub-tit {
    color: inherit;
    /* color: #0E0300FF; */
    width: 200px;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .container-right {
    height: 100%;
    flex: 0 1;
  }
  .content-right .container-center-content {
    float: right;
  }
</style>