/**
 * test模块接口列表
 */

import base from './base'; // 导入接口域名列表
// import axios from '../../request/http'; // 导入http中创建的axios实例
import axios from 'axios'; // 引入axios
import qs from 'qs'; // 根据需求是否导入qs模块
import { store } from '../../store'

// 域名全部走测试ok
const plugIn = {
    // 店铺积分换取奖励规则查询入口
    redeemways() {
        return axios.get(`${base.haiyang}/points/redeemways?myShopifyDomain=${store.getters.urlObj.shopifydomain}`)
    },
    redeemRewards(redeemRuleId){
        return axios({
            url:`${base.haiyang}/points/redeemrewards`,
            method: 'post',
            data:{
                myShopifyDomain: store.getters.urlObj.shopifydomain,
                customerShopifyId: store.getters.urlObj.customerid || '115310627314723954',
                redeemRuleId: redeemRuleId,
            },
            transformRequest:[function(data){
               var ret='';
               for(var it in data){
                   ret+=encodeURIComponent(it)+'='+encodeURIComponent(data[it])+'&'
               }
               return ret
            }]
        })
    },
    // 店铺积分获取规则查询入口
    earnways() {
        return axios.get(`${base.haiyang}/points/earnways`, { params: {
            myShopifyDomain: store.getters.urlObj.shopifydomain
        }});
    },
    // 店铺用户的积分和界面设计查询入口
    widget() {
        return axios.get(`${base.haiyang}/widget`, { params: {
            myShopifyDomain: store.getters.urlObj.shopifydomain,
            customerShopifyId: store.getters.urlObj.customerid || null
        }});
    },
    // 店铺用户的积分和界面设计查询入口
    rewards() {
        return axios.get(`${base.haiyang}/rewards?myShopifyDomain=${store.getters.urlObj.shopifydomain}&customerShopifyId=${store.getters.urlObj.customerid}`)
    },
    // 用户获取当前店铺的分享链接入口
    reffer() {
        return axios.get(`${base.haiyang}/smartlink`, { params: {
            myShopifyDomain: store.getters.urlObj.shopifydomain,
            customerShopifyId: store.getters.urlObj.customerid || null
        }});
    },

}

export default plugIn
