<template>
  <div class="header-warp">
    <div
      :class="{ hide: !inHomePage, header: true }"
      v-bind:style="headBackgroundColor"
      ref="Bar"
    >
      <div class="top">
        <div ref="tit" class="tit">
          Welcome to
        </div>
        <div ref="info" class="info">
          {{ programName }}
        </div>
      </div>
    </div>
    <div :class="{ hide: inHomePage && !showSmallheader, smallHeader: true }"
      v-bind:style="{ backgroundColor: themeColor, ...headBackgroundColor }">
      <div class="top">
        <div class="info" v-bind:style="{ marginLeft: (inHomePage ? '0px' : '30px') }">
          {{ hadLogin ? `${userObj.points} ${pointAlias}` : programName }}
        </div>
      </div>
    </div>
    <div class="panel-header-container">
      <div class="panel-fixed-header">
        <div class="header-flex align-items-center">
          <div class="ml-2 header-flex-back">
            <i @click="$router.back(-1)" v-bind:style="{color:fontColor}" v-show="!inHomePage" class="back iconfont iconleft-arrow-2"></i>
          </div>
        </div>
        <button @click="send('Toggle')" v-bind:style="{color:fontColor}" class="iconfont iconclose panel-header-icon" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sendMsg } from "../util";

export default {
  data() {
    return {
      showSmallheader: false,
    };
  },
  computed: {
    ...mapGetters({
      inHomePage: "inHomePage",
      hadLogin: "hadLogin",
      userObj: "userObj",
      programName: "programName",
      fontColor: "fontColor",
      themeColor: "themeColor",
      pointAlias: "pointAlias",
    }),
    headBackgroundColor: function() {
      // return { background: `linear-gradient(135.19deg, ${this.themeColor}, hsla(0, 97%, 2%, 1))` };
      return { color: this.fontColor };
    },
  },
  mounted() {
    let $this = this;
    // 监听这个dom的scroll事件
    document.body.onscroll = () => {
      $this.handleScroll();
    };
  },
  methods: {
    handleScroll() {
      // let Bar = this.$refs.Bar;
      let tit = this.$refs.tit;
      let info = this.$refs.info;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (tit && scrollTop < 80) {
        this.showSmallheader = false;
        // Bar.style.opacity = (130 - scrollTop) / 100;
        tit.style.opacity = (80 - scrollTop) / 80;
        info.style.opacity = (80 - scrollTop) / 80;
        tit.style.bottom = scrollTop / 5 + "px";
        info.style.bottom = scrollTop / 7 + "px";
        return;
      }
      this.showSmallheader = true;
    },
    send(msg) {
      sendMsg(window, msg);
    }
  },
  props: {
    value: {
      type: Number,
    },
    backUrl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  background-color: transparent;
  color: white;
  font-weight: 800;
}
.header-flex {
  flex: 1;
}
.header-flex-back {
  line-height: 65px; 
} 
.panel-header-container { position: fixed; width: 100%;
  z-index: 22;
  top: 0;
}
.panel-header-container .panel-fixed-header {
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.panel-header-container .panel-fixed-header .panel-header-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all .15s ease-in-out;
  flex: 0 1;
  padding: 0;
  font-size: 15px;
}
.hide {
  opacity: 0;
  height: 65px;
  transition: all 0.5s;
}
.smallHeader {
  transition: all 1s;
  -webkit-transition: all 1s; /* Safari */
  color: white;
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  height: 65px;
  z-index: 5;
}
.smallHeader .info {
  font-size: 25px;
  align-items: center; 
}
.top {
  padding: 40px 30px 0;
}
.smallHeader .top {
  padding: 0 30px;
  height: 65px;
  display: flex;
  align-items: center;
}
.tit {
  margin-bottom: 5px;
  font-size: 25px;
  position: relative;
}
.info {
  font-size: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

</style>
